<template>
    <v-list-item two-line >
      <v-list-item-icon>
        <v-icon large class="mt-4">
          mdi-note
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{file.name}}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ formatByte(file.size) }}
         </v-list-item-subtitle>
      </v-list-item-content>

      <v-progress-linear
        color="white"
        indeterminate
        rounded
        height="6"
        style="width:100px"
        v-if="!loading"
      ></v-progress-linear>
      <v-list-item-icon class="" v-if="uploaded && loading">
          <v-icon color="white" class="mt-4"> mdi-check </v-icon>
        </v-list-item-icon>
      <v-list-item-action>
        <v-list-item-icon class="" v-if="uploaded">
          <v-btn icon tile outlined :color="modalColor" class="rounded-lg" @click="$emit('delete')">
            <i class="far fa-trash-alt white--text"></i>
          </v-btn>
        </v-list-item-icon>
      </v-list-item-action>
     </v-list-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import colorMxn from "@/mixins/colorMxn";
import validationMxn from "@/mixins/validation_mixin";
import generalMxn from '@/mixins/general_mixin';

export default {
  name: 'fileUploadComponent',
  props: ['file'],
  mixins: [colorMxn, validationMxn, generalMxn],
  data() {
    return {
      uploaded: true,
      loading: false,
      error: '',
      actionClass: 'success',
			message: '',
    }
  },
  computed: {
		...mapGetters(['getSession']),
	},
  mounted() {
    if (this.file) {
      this.submitFiles();
    }
  },
  methods: {
    ...mapActions(['performPutActions', 'refreshAuthToken']),
    async submitFiles() {
      this.loading = true;
      const formData = new FormData()

      formData.append('profile_pic', this.file);

      const fullPayload = {
        params: formData,
        endpoint: '/profile/upload/avatar'
      }

      const response = await this.uploadImage(fullPayload);
      if (response.status) {
        await this.update(response.url);
      }
    },

    async update(fileName) {
			this.error = '';
      this.uploaded = false;
			const session = this.getSession;
			const payload = {
				avatar: fileName,
			}
      const endpoint = session.user_type === 1 ? `/profile/update-customer/${session.customer.id}` : `/profile/update-writer/${session.writer.id}`;
			const fullPayload = {
				params: payload,
				endpoint: endpoint,
			}

			const response = await this.performPutActions(fullPayload);
			this.message = response.message;
			this.actionClass = response.status ? "success" : "error";
      this.uploaded = true;
			if (response.status) {
				await this.refreshAuthToken();
			}
		},
  }
}
</script>